import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

// import injectTapEventPlugin from 'react-tap-event-plugin';

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Droid Sans', 'Droid Serif']
  }
});


// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin();

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
